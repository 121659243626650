import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import {HttpLink, HttpLinkHandler} from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import {environment} from "../environments/environment";
import {HttpHeaders} from "@angular/common/http";

const uri = environment.graphql;
export function createApollo(httpLink: HttpLink): { cache: InMemoryCache; link: HttpLinkHandler } {
  return {
    link: httpLink.create({
      headers: new HttpHeaders({
        'Apollo-Require-Preflight': 'true',
        authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjMsImVtYWlsIjoidGVzdDJAc3RhcnR1cC13ZXJrLmRlIiwiaWF0IjoxNzAwMTQwMzE2LCJleHAiOjE3MDI3MzIzMTZ9.FjDOdarICSZgeJUkb0Jj7wiYKxHl3UEwW-_vApfA37s"
      }),
      uri,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    }),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
