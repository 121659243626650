import { Route } from '@angular/router';
import {DesignSummaryComponent} from "./ui/test/design-summary/design-summary.component";
import {AuthGuard} from "./auth/auth.guard";
import {TermsAndConditionsComponent} from "./pages/core/termsAndConditions/termsAndConditions.component";
import {LicenseComponent} from "./pages/core/license/license.component";
import {DataPrivacy} from "./pages/core/privacy/data-privacy.component";

export const appRoutes: Route[] = [
  { path: 'design-summary', component: DesignSummaryComponent , title: 'Design Summary'},
  { path: 'terms-conditions', component: TermsAndConditionsComponent , title: 'Leaderio - Terms And Conditions'},
  {path: 'privacy', component: DataPrivacy, title: 'Leaderio - Data Privacy'},
  { path: 'license', component: LicenseComponent , title: 'Leaderio - License'},
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), title: 'Leaderio', },
  { path: '',
    loadChildren: () => import('./pages/core/core.module').then(m => m.CoreModule),
    canActivate: [AuthGuard()]
  },
];
