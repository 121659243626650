import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'leaderio-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit{
  @ViewChild('rangeSlider') rangeSlider: ElementRef;

  @Input() value: number = 1;
  @Input() minValue: number = 1;
  @Input() maxValue: number = 10;
  @Input() name = '';
  @Input() subLine = '';
  @Input() textLeft = 'weak ' + this.minValue;
  @Input() overlayText =  '';
  @Input() textRight = this.maxValue + ' strong';
  @Input() editable: boolean = true;
  @Input() showValueInHead = true;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.valueChange.emit(this.value)
  }

  public changeValue(change: any): void {
    this.value = change.target.value;
    this.valueChange.emit(this.value);
    this.rangeSlider.nativeElement.focus();
  }

  protected readonly JSON = JSON;
}
