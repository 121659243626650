import {Injectable} from '@angular/core';
import {
  AllTopicClustersDocument, CreateFeedbackDocument, CreateFeedbackInput,
  FinishJourneyDocument,
  FinishJourneyInput,
  FinishJourneyMutation,
  GetCurrentPhaseDocument, GetCurrentPhaseQuery, GetCurrentPhaseQueryVariables, Journey,
  RecommendModulesDocument,
  RecommendModulesInput,
  RecommendModulesMutation, ResetBreakAndCompletedAtDocument, ResetBreakAndCompletedAtMutation,
  SetModuleEnrollmentDataInput,
  SetModuleEnrollmentDocument,
  SetModuleEnrollmentMutation,
  StartBreakDocument,
  StartBreakInput,
  StartBreakMutation,
  StartJourneyDocument,
  StartJourneyInput,
  StartJourneyMutation,
  SwitchModuleDocument,
  SwitchModuleInput,
  SwitchModuleMutation,
  TopicCluster
} from "../../../graphql/generated";
import {Apollo} from "apollo-angular";
import {BehaviorSubject, debounceTime, Observable, Subject, tap} from "rxjs";
import {ToastService} from "../toast/toast.service";
import {JourneyService} from "../journey/journey.service";


@Injectable({
  providedIn: 'root'
})
export class JourneyDataService {
  private $currentPhase: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public $JourneyData: BehaviorSubject<Journey> = new BehaviorSubject<Journey>({} as Journey);
  constructor(private apollo: Apollo,
              private toast: ToastService,
              private journeyService: JourneyService,
              ) {
    this.$save.subscribe((input) => {
      this.apollo.mutate<SetModuleEnrollmentMutation>({
        mutation: SetModuleEnrollmentDocument,
        variables: {input: input}
      }).pipe(tap(res => {
        this.$currentPhase.next(res.data?.setModuleEnrollmentData!.phase!)
        this.toast.show('Data saved', "toast--success", "toast--top-left", {})
      })).subscribe();
    })
  }

  init() {
    this.$currentPhase.next('');
    this.$JourneyData.next({} as Journey);
  }

  private saveSubject = new Subject<SetModuleEnrollmentDataInput>();
  $save = this.saveSubject.pipe(debounceTime(200))

  public enrollJourney(id: string, recommend_modules_input: RecommendModulesInput) {
    const input = <StartJourneyInput>{
      module_id: id,
      recommend_modules_input: recommend_modules_input
    };

    return this.apollo.mutate<StartJourneyMutation>({
      mutation: StartJourneyDocument,
      variables: {
        input: input
      }
    })
  }

  public startBreak(input: StartBreakInput) {
    return this.apollo.mutate<StartBreakMutation>({
      mutation: StartBreakDocument,
      variables: {
        input: input
      }
    });
  }

  public switchModuleEnrollment(input: SwitchModuleInput) {
    return this.apollo.mutate<SwitchModuleMutation>({
      mutation: SwitchModuleDocument,
      variables: {
        input: input
      }
    })
  };

  public saveDataForEnrollment(input: SetModuleEnrollmentDataInput) {
    if(!this.$JourneyData.getValue().completed_at && !this.journeyService.isInBreak(this.$JourneyData.getValue().current_module_enrollment?.break_end_at)) {
      this.saveSubject.next(input);
    }
  }

  public getAllTopicClusters() {
    return this.apollo.query<{ topicClusters: TopicCluster[] }>({
      query: AllTopicClustersDocument
    })
  }

  public getRecommendedModules(input: RecommendModulesInput) {
    return this.apollo.mutate<RecommendModulesMutation>({
      mutation: RecommendModulesDocument,
      variables: {
        input: input
      }
    })
  }

  public finishJourney(input: FinishJourneyInput) {
    return this.apollo.mutate<FinishJourneyMutation>({
      mutation: FinishJourneyDocument,
      variables: {input: input}
    })
  }

  public getCurrentPhase(id: string) {
    const input = <GetCurrentPhaseQueryVariables>{
      input: id
    };

    return new Observable<any>(observer => {
      if (this.$currentPhase.value !== '') {
        observer.next({
          data: {
            journey: {
              current_module_enrollment: {
                phase: this.$currentPhase.value
              }
            }
          }
        });
        observer.complete();
        return;
      }

      this.apollo.query<GetCurrentPhaseQuery>({
        query: GetCurrentPhaseDocument,
        fetchPolicy: 'no-cache',
        variables: input
      }).subscribe((data) => {
        this.$currentPhase.next(data.data.journey?.current_module_enrollment.phase!)
        observer.next(data);
        observer.complete();
      });
    });
  }

  public postMissingTopic(topic: string, user_mail: string, description: string) {
    const input: CreateFeedbackInput = {
      message: 'Topic "' + topic + '" was requested by user ' + user_mail + ' with the following description: ' + description,
      type: 'NATIVE_FEEDBACK'
    }

    return this.apollo.mutate({
      mutation: CreateFeedbackDocument,
      variables: {
        input: input
      }
    });
  }

  public resetModuleEnrollmentFinishedAndBreak(id: number) {
    return this.apollo.mutate<ResetBreakAndCompletedAtMutation>({
      mutation: ResetBreakAndCompletedAtDocument,
      variables: {
        input: id
      }
    })
  }
}
