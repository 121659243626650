import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, switchMap, tap} from "rxjs";
import {
  GetJourneyDataDocument,
  GetJourneyDataQuery,
  GetJourneyDataQueryVariables,
  Journey,
  ModuleEnrollmentPhase,
  RecommendModulesInput,
  SetModuleEnrollmentDataInput, SetModuleEnrollmentDocument, SetModuleEnrollmentMutation,
  StartBreakInput,
} from "../../../graphql/generated";
import {Apollo} from "apollo-angular";
import {AuthService} from "../auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {JourneyDataService} from "../journey-backend/journey-data.service";
import {Router} from "@angular/router";
import {ApolloQueryResult} from "@apollo/client";

export interface PhaseAction {
  name: string,
  action: () => void
}

@Injectable({
  providedIn: 'root'
})
export class JourneyStepService {
  public $Journey: BehaviorSubject<Journey> = new BehaviorSubject<Journey>({} as Journey)

  get currentJourney() {
    return this.$Journey.value;
  }

  get currentEnrollment() {
    return this.currentJourney.current_module_enrollment;
  }

  get userData() {
    return JSON.parse(this.currentEnrollment.user_data!);
  }

  get user() {
    return this.authService.user.value;
  }

  constructor(private apollo: Apollo,
              private authService: AuthService,
              private translateService: TranslateService,
              private journeyDataService: JourneyDataService,
              private router: Router,) {
    this.$Journey.subscribe(data => {
        this.journeyDataService.$JourneyData.next(data)
    })
  }


  /**
   * Initializes the Journey by fetching it from the backend
   * and setting it to the $Journey BehaviorSubject
   * @param s
   */
  public init(s: string) {
    return new Observable((observer) => {
      this.clearJourney();
      this.fetchJourney(s).subscribe((data: any) => {
        if (!data && data.data.journey === null) {
          this.router.navigate(['dashboard']);
        }
        observer.next(data);
        observer.complete();
      });
    });
  }

  /**
   * Checks if the break period for the current module enrollment is over and updates the phase to FollowUp if so.
   * This method creates an Observable that emits the updated journey data after checking and potentially updating
   * the enrollment phase based on the break end time. If the current phase is 'Break' and the break end time has passed,
   * it triggers a mutation to update the module enrollment phase to 'FollowUp' and phase step index to 1.
   *
   * @param {ApolloQueryResult<GetJourneyDataQuery>} data - The current journey data fetched from the backend.
   * @returns {Observable<any>} An Observable that emits the journey data, potentially updated if the break is over.
   */
  private updateIfBreakIsOver(data: ApolloQueryResult<GetJourneyDataQuery>) {
    return new Observable((observer) => {
      // Check if the current phase is 'Break' and if the break end time has passed
      if (data.data && data.data.journey?.current_module_enrollment?.phase === ModuleEnrollmentPhase.Break) {
        if (data.data.journey.current_module_enrollment.break_end_at && new Date(data.data.journey.current_module_enrollment.break_end_at).getTime() - new Date().getTime() <= 0) {
          const input = <SetModuleEnrollmentDataInput>{
            module_enrollment_id: data.data.journey.current_module_enrollment!.id,
            user_data: data.data.journey.current_module_enrollment.user_data,
            phase: ModuleEnrollmentPhase.FollowUp,
            phase_step_index: 1
          };
          // Trigger a mutation to update the enrollment phase to 'FollowUp'
          this.apollo.mutate<SetModuleEnrollmentMutation>({
            mutation: SetModuleEnrollmentDocument,
            variables: {input: input}
          }).subscribe(res => {
            if (res.data) {
              observer.next(data)
              observer.complete();
            }
          })
        }
      }
      observer.next(data);
      observer.complete();
    })
  }


  /**
   * Fetches journey data based on a given identifier and updates the journey's phase to FollowUp if the break period is over.
   * This method queries the backend for journey data using Apollo's GraphQL client. It then checks if the break period
   * for the current module enrollment is over by calling `updateIfBreakIsOver`. If the break is over, it updates the
   * journey's current module enrollment phase to FollowUp and the phase step index to 1. Finally, it updates the
   * `$Journey` BehaviorSubject with the new journey data, ensuring that the latest journey state is available throughout the application.
   *
   * @param {string} s - The identifier for the journey to fetch.
   * @returns {Observable<any>} An Observable that emits the updated journey data.
   */
  private fetchJourney(s: string) {
    return this.apollo.query<GetJourneyDataQuery>({
      query: GetJourneyDataDocument,
      variables: <GetJourneyDataQueryVariables>{
        input: s
      },
      fetchPolicy: 'no-cache'
    }).pipe(switchMap((data) => {
      const journey = data.data.journey as Partial<Journey>;
      return this.updateIfBreakIsOver(data).pipe(tap((res) => {
        if (res && journey.current_module_enrollment && journey.current_module_enrollment.phase === ModuleEnrollmentPhase.Break && journey.current_module_enrollment.break_end_at && new Date(journey.current_module_enrollment.break_end_at).getTime() - new Date().getTime() <= 0){
          journey.current_module_enrollment!.phase = ModuleEnrollmentPhase.FollowUp;
          journey.current_module_enrollment.phase_step_index = 1;
        }
        if (!journey) return;
        this.$Journey.next(journey as Journey);
        return res;
      }))
    }))
  }

  enrollJourney(moduleId: string, recommendModulesInput: RecommendModulesInput) {
    return this.journeyDataService.enrollJourney(moduleId, recommendModulesInput);
  }

  /**
   * Clears the complete Journey
   * @private
   */
  private clearJourney() {
    this.$Journey.next({} as Journey);
  }

  /**
   * Update the current phases data. The Data is always a partial
   * Only the provided keys in data will be added OR overwritten
   * @param phaseId
   * @param data
   * @param index
   */
  updateJourneyData(data: any, index: number, phaseId: ModuleEnrollmentPhase = this.currentEnrollment.phase) {
    const journeyUpdate = this.$Journey.value;
    const currentData = this.currentEnrollment?.user_data ? JSON.parse(this.currentEnrollment!.user_data!) : {};
    const newData = {
      ...currentData,
      [phaseId]: {
        ...(currentData && currentData[phaseId] ? currentData[phaseId] : {}),
        [index]: data
      }
    };
    journeyUpdate.current_module_enrollment.user_data = JSON.stringify(newData);
    this.$Journey.next(journeyUpdate);
    this.saveJourneyDataForCurrentEnrollment();
  }

  public saveJourneyDataForCurrentEnrollment(phaseInput?: ModuleEnrollmentPhase, stepIndex?: number) {
    if(this.$Journey?.value?.uuid) {
      return this.journeyDataService.getCurrentPhase(this.$Journey.value.uuid).subscribe((data) => {
        const phase = data.data.journey?.current_module_enrollment.phase;
        const input = <SetModuleEnrollmentDataInput>{
          module_enrollment_id: this.currentEnrollment!.id,
          user_data: this.currentEnrollment.user_data,
          phase: phaseInput ?? phase,
          phase_step_index: stepIndex === undefined ? undefined : stepIndex
        };
        return this.journeyDataService.saveDataForEnrollment(input);
      });
    }

    return Subscription.EMPTY
  }

  public startBreakForCurrentEnrollment(days: number) {
    const input = <StartBreakInput>{
      module_enrollment_id: this.currentEnrollment!.id,
      duration_in_days: days
    };
    return this.journeyDataService.startBreak(input)
  }

  reset() {
    this.$Journey.next({} as Journey);
  }
}
