import {Component, Input} from '@angular/core';
import {ModalService} from "../../../services/modal/modal.service";

@Component({
  selector: 'leaderio-simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent {

  @Input() cancelButton = '';
  @Input() headerText = '';
  @Input() headerIcon = 'assets/img/icons/info.svg';
  @Input() bodyTitle = '';
  @Input() bodyText = '';
  @Input() redConfirmButton = false;
  @Input() confirmButton = '';

  constructor(private modalService: ModalService) {}

  public handleOutput(event: string) {
    this.modalService.close(event);
  }
}
