import { Injectable } from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {Subject, take, throttleTime} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) {
    this.openSubject.pipe(
      throttleTime(1000) // adjust throttle time as needed
    ).subscribe(([content, options]) => {
      const newModal = new ModalRefWrapper(this.modalService.open(content, options));
      this.modalRef.push(newModal);
    });
  }

  private modalRef: ModalRefWrapper[] = [];
  private openSubject = new Subject<[any, NgbModalOptions?]>();

  public open(content: any, options?: NgbModalOptions) {
    this.openSubject.next([content, options]);
    return this.modalRef[this.modalRef.length - 1];
  }

  public gotOpenModal(): boolean {
    if (this.modalRef.length > 0) {
      if (this.modalRef[0].closed) {
        this.modalRef.pop()
      }
    }
    return this.modalRef.length > 0;
  }

  public close(payload?: any): void {
    this.modalRef.pop()?.dismiss(payload);
  }
}

class ModalRefWrapper {
  closed = false;
  dismissed = new Subject<any>()
  get componentInstance() {
    return this.ref.componentInstance;
  }
  constructor(public ref: NgbModalRef) {
    ref.result.then(() => {
      this.closed = true;
    }).catch(() => {
      this.closed = true;
    });
    this.ref.dismissed
      .pipe(take(1))
      .subscribe((payload) => {
      this.dismissed.next(payload);
    });
  }
  dismiss(payload?: any): void {
    this.ref.dismiss(payload);
  }
}
