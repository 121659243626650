import {CanActivateFn, Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {inject} from "@angular/core";
import {environment} from "../../environments/environment";

export function AuthGuard(): CanActivateFn {
  return () => {
    const authService: AuthService = inject(AuthService);
    const isLoggedIn = authService.getLoginStatus();
    const router: Router = inject(Router);

    if (!isLoggedIn) {
      router.navigate(['/auth']).then( () => {
        return false
      });
    }

    if (!environment.production && !isLoggedIn) {
      console.warn('Not authenticated, but in development mode.')
      console.warn('Consider authenticating at /auth')
    }

    authService.updateUser();
    return true
  }
}
