<leaderio-modal [headerIcon]="'assets/img/icons/feedback.svg'"
                 [headerText]="'FEEDBACK_MODAL.HEADER' | translate"
                 [cancelButton]="'FEEDBACK_MODAL.CANCEL' | translate"
                 [confirmButton]="'FEEDBACK_MODAL.SUBMIT' | translate"
                 [confirmDisabled]="confirmDisabled"
                 (output)="handleOutput($event)">
    <h1 class="feedback-modal__headline">
        {{'FEEDBACK_MODAL.TITLE' | translate}}
        <span class="subline">{{'FEEDBACK_MODAL.TEXT' | translate}}</span>
    </h1>
    <leaderio-text-area-input (valueChange)="textInputChange($event)" subLine="{{'FEEDBACK_MODAL.INPUT_SUBLINE' | translate}}"></leaderio-text-area-input>
</leaderio-modal>
