<leaderio-modal [headerIcon]="'assets/img/icons/feedback.svg'"
                 [headerText]="'CONTACT_MODAL.HEADER' | translate"
                 [cancelButton]="'CONTACT_MODAL.CANCEL' | translate"
                 [confirmButton]="'CONTACT_MODAL.SUBMIT' | translate"
                 (output)="handleOutput($event)">
    <h1>{{'CONTACT_MODAL.TITLE' | translate}}</h1>
    <div class="options">
        <div class="options__option" (click)="contactType = 'chat'">
            <leaderio-state-dot [dotState]="contactType === 'call' ? 'waiting' : 'done'"></leaderio-state-dot>
            <p class="subline">{{'CONTACT_MODAL.OPTION_CHAT' | translate}}</p>
        </div>
        <div class="options__option" (click)="contactType = 'call'">
            <leaderio-state-dot [dotState]="contactType === 'call' ? 'done' : 'waiting'"></leaderio-state-dot>
            <p class="subline">{{'CONTACT_MODAL.OPTION_CALL' | translate}}</p>
        </div>
    </div>
    <p class="subline">{{'CONTACT_MODAL.INPUT_TEXT' | translate}}</p>
    <leaderio-text-area-input (valueChange)="inputText = $event" subLine="{{'CONTACT_MODAL.INPUT_SUBLINE' | translate}}"></leaderio-text-area-input>
</leaderio-modal>
