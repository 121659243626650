<leaderio-modal [headerIcon]="'assets/img/icons/info.svg'"
                 [headerText]="'TOOLS.ADD_TASK' | translate"
                 [cancelButton]="'FORMS.CANCEL' | translate"
                 [confirmButton]="'FORMS.CONFIRM' | translate"
                 [confirmDisabled]="!postItTextArea.value"
                 (output)="handleOutput($event)">
  <div class="post-it-modal__wrapper">
    <leaderio-text-area-input
      #postItTextArea
      [value]="postItText"
      (input)="setTextNew($event)"
      [subLine]="'TOOLS.ENTER_TASK_NAME' | translate">
    </leaderio-text-area-input>
    <div class="color-picker">
      <h5 class="color-picker__text">{{'TOOLS.CHOOSE_COLOR' | translate}}</h5>
      <button class="button color-picker__dot color-picker__dot--red"
              [class.color-picker__dot--selected]="postItColor === 'red'"
              (click)="handleColorClick('red')">
      </button>
      <button class="button color-picker__dot color-picker__dot--green"
              [class.color-picker__dot--selected]="postItColor === 'green'"
              (click)="handleColorClick('green')">
      </button>
      <button class="button color-picker__dot color-picker__dot--blue"
              [class.color-picker__dot--selected]="postItColor === 'blue'"
              (click)="handleColorClick('blue')">
      </button>
      <button class="button color-picker__dot color-picker__dot--purple"
              [class.color-picker__dot--selected]="postItColor === 'purple'"
              (click)="handleColorClick('purple')">
      </button>
    </div>
  </div>
</leaderio-modal>
