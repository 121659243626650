import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestModalComponent } from '../../ui/test/test-modal/test-modal.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { TutorialModalComponent } from './tutorial-modal/tutorial-modal.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { AtomicModule } from '../../ui/components/atomic.module';
import { TranslateModule } from '@ngx-translate/core';
import { SwitchModuleModalComponent } from './switch-module-modal/switch-module-modal.component';
import { SvgIconComponent } from 'angular-svg-icon';
import {TopicModalComponent} from "./topic-modal/topic-modal.component";
import {CancelJourneyModalComponent} from "./cancel-journey-modal/cancel-journey-modal.component";

@NgModule({
  declarations: [
    TestModalComponent,
    FeedbackModalComponent,
    SimpleModalComponent,
    TutorialModalComponent,
    ContactModalComponent,
    SwitchModuleModalComponent,
    TopicModalComponent,
    CancelJourneyModalComponent
  ],
  imports: [CommonModule, TranslateModule, AtomicModule, SvgIconComponent],
  exports: [
    TestModalComponent,
    FeedbackModalComponent,
    SimpleModalComponent,
    TutorialModalComponent,
    ContactModalComponent,
    TopicModalComponent,
    CancelJourneyModalComponent,
    SwitchModuleModalComponent,
  ],
})
export class ModalModule {}
