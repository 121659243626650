import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public journeyOpen= false

  constructor() { }

  groupByItem(array: any, property: string) {
    let hash = {},
      props = property.split('.');
    array.forEach((result: any) => {
      const key = props.reduce((acc: any, prop: any) => {
        return acc && acc[prop];
      }, result)

      if (!hash[key]) hash[key] = []
      hash[key].push(result)
    })
    return hash
  }

  countInArray(arr, prop) {
    return arr.reduce((prev, curr) => (prev[curr[prop]] = ++prev[curr[prop]] || 1, prev), {});
  }
}
