import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NxWelcomeComponent } from './nx-welcome.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DesignSummaryComponent } from './ui/test/design-summary/design-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GraphQLModule } from './graphql.module';
import { ConfettiService } from './services/confetti/confetti.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AtomicModule } from './ui/components/atomic.module';
import { ToastsContainer } from './components/toasts-container.component';
import { authInterceptorProvider } from './auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleModalComponent } from './components/modal/simple-modal/simple-modal.component';
import { MarkdownModule } from 'ngx-markdown';
import { ModalModule } from './components/modal/modal.module';
import * as Sentry from "@sentry/angular";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?v' + Date.now()
  );
}

@NgModule({
  declarations: [
    AppComponent,
    NxWelcomeComponent,
    DesignSummaryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    HttpClientModule,
    MarkdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgOptimizedImage,
    ReactiveFormsModule,
    GraphQLModule,
    AtomicModule,
    ToastsContainer,
    BrowserAnimationsModule,
    ModalModule,
  ],
  exports: [ToastsContainer, SimpleModalComponent],
  providers: [
    ConfettiService,
    importProvidersFrom(HttpClientModule),
    authInterceptorProvider,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
