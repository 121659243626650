import { Injectable, TemplateRef } from '@angular/core';
export type ToastType=
  'toast--neutral' |
  'toast--success' |
  'toast--error';

export type ToastPosition=
  'toast--top' |
  'toast--center' |
  'toast--top-left' |
  'toast--top-right' |
  'toast--bottom' |
  'toast--bottom-left' |
  'toast--bottom-right';
@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, toastType: ToastType = 'toast--error', toastPosition: ToastPosition = 'toast--top-left', options: any = {},) {
    this.toasts.push({ textOrTpl, ...options , toastType, toastPosition});
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
