import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'leaderio-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss'],
})
export class BackdropComponent {
   @Input() open: boolean = false;
   @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
}
