<ng-container *ngIf="specialType">
  <button
    *ngIf="specialType"
    class="button button--icon button--small"
    [class.button--journey]="!noData"
    [class.button--primary]="specialType == 'journeyNext'"
    [class.button--secondary]="specialType == 'journeyPrev'"
    [class.button--fill-height]="fillHeight"
    tabindex="-1"
    [disabled]="disabled">
    <div class="button__text"
         [class.button__text--show-text]="noData"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div class="button__icon-wrapper"
         [class.button__icon-wrapper--only-desktop]="noData"
         *ngIf="icon">
      <svg-icon class="button__icon"
                [svgClass]="'button__icon'"
                [src]="icon"/>
    </div>
    <div class="button__icon-wrapper button__icon-wrapper--only-mobile"
         *ngIf="!noData"
    >
      <svg-icon class="button__icon"
                [svgClass]="'button__icon'"
                [src]="specialType == 'journeyPrev' ? 'assets/img/icons/arrow-left.svg' : 'assets/img/icons/arrow-right.svg'"/>
    </div>
  </button>
</ng-container>

<ng-container *ngIf="!specialType">
  <button class="button {{buttonStyle}} {{iconAlign}}"
          *ngIf="!specialType"
          [class.button--icon]="icon"
          [class.button--small]="icon || buttonSize === 'button--small'"
          [class.w100]="fullWidth"
          [class.w100-mobile]="fullWidthMobile"
          [class.button--icon-only]="iconOnly"
          [type]="buttonType"
          [disabled]="disabled"
          [class.button--fill-height]="fillHeight"
          tabindex="-1"
  >
    <div class="button__text"
         *ngIf="!iconOnly">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div class="button__icon-wrapper"
         [class.button__icon-wrapper--icon-only]="iconOnly"
         [class.button__icon-wrapper--desktop-only]="disableIconMobile"
         *ngIf="icon">
      <svg-icon class="button__icon"
                [svgClass]="'button__icon'"
                [src]="icon"/>
    </div>
  </button>
</ng-container>
<ng-template #content><ng-content></ng-content></ng-template>
