import { Component } from '@angular/core';
import {ModalService} from "../../../services/modal/modal.service";

@Component({
  selector: 'leaderio-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent {

  public inputText = '';
  public confirmDisabled = true;
  constructor(private modalService: ModalService) {}

  public handleOutput(event: string) {
    if(event === 'confirm' && this.inputText !== '') {
      this.modalService.close({success: true, value: this.inputText});
    } else if (event === 'cancel') {
      this.modalService.close({success: false});
    }
  }

  textInputChange(textInput: string) {
    this.inputText = textInput;
    this.confirmDisabled = this.inputText.length <= 9;
  }
}
