<div class="slider__wrapper">
  <div class="slider__headline-wrapper">
    <h5 class="slider__title" *ngIf="name">
      {{name}}<span *ngIf="showValueInHead" class="slider__value">: {{value}}</span>
    </h5>
    <p *ngIf="subLine" class="slider__subline">{{subLine}}</p>
  </div>
    <div class="slider__main">
        <p class="slider__p--front">{{textLeft}}</p>
      <div class="range-slider" style='--min:1; --step:1;'
           [style.--max]="maxValue"
           [style.--value]="value"
      >
        <input type="range" min="1" max="{{maxValue}}" step="1"
               #rangeSlider
               id="range-slide"
               [value]="value"
               [disabled]="!editable"
               (change)="changeValue($event)"
        >
        <output>
          <ng-container *ngIf="overlayText">{{overlayText}}</ng-container>
          <ng-container *ngIf="!overlayText">{{'FORMS.SIGNAL_WEIGHT' | translate: {number: this.value} }}</ng-container>
          <div class="bottom-arrow"></div>
        </output>
        <div class='range-slider__progress'></div>
      </div>
        <p class="slider__p--back">{{textRight}}</p>
    </div>
</div>
