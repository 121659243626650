import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public get Document(): Document {
    return this.document;
  }

  public scrollToTop() {
    //this.document.body.scrollTop = 0;
    this.document.body.scrollTo({top: 0, behavior: 'smooth'})
  }
}
