import {Component, EventEmitter, Input, Output} from '@angular/core';

export type postItColor =
  '' |
  'red' |
  'green' |
  'blue' |
  'purple'

export type postItType =
    'postIt' |
    'chip' |
    'stepChip'

export interface PostIt {
  color: postItColor;
  text: string;
}

@Component({
  selector: 'leaderio-post-it-button',
  templateUrl: './post-it-button.component.html',
  styleUrls: ['./post-it-button.component.scss'],
})
export class PostItButtonComponent {
  @Input() color: postItColor = '';
  @Input() text: string = '';
  @Input() type: postItType = 'postIt';
  @Input() selected: boolean = false;
  @Input() dragButton = false;
  @Output() selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() disabled: boolean = false;
  @Output() disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() output: EventEmitter<string> = new EventEmitter<string>();

  public handleOutput(output: string) {
    this.updateSelected()
    this.output.emit(output);
  }

  public updateSelected(val?: boolean) {
    if (val !== undefined) {
      this.selected = val;
    } else {
      this.selected = !this.selected;
    }
    this.selectedChange.emit(this.selected);
  }
}
