import {Injectable} from '@angular/core';
import {JourneyStepService} from "../../../../services/journeyStep/journey-step.service";
import {Module, ModuleEnrollmentPhase, ModuleStep} from "../../../../../graphql/generated";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../../services/auth/auth.service";
import {
  BehaviorSubject,
  map,
  Observable,
  first, take, of
} from "rxjs";
import {JourneyDataService} from "../../../../services/journey-backend/journey-data.service";
import {ScrollingService} from "../../../../services/scrolling/scrolling.service";

export type NavPhases = 'start' | 'module_selection' | 'module' | 'practical' | 'follow_up' | 'completed' | 'break';

export interface Step {
  name: string,
  nameTranslateString?: string,
  subLineTranslateString?: string,
  subLine?: string,
  template: string,
  data?: any
}

@Injectable({
  providedIn: 'root'
})
export class JourneyNavigationService {
  first: boolean = true;
  highestStepSoFar: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private translateService: TranslateService,
              private authService: AuthService,
              private journeyStepService: JourneyStepService,
              private journeyDataService: JourneyDataService,
              private scrollService: ScrollingService) {
    this.translateService.onLangChange.subscribe(() => {
      this.reTranslateAllSteps();
    })
  }

  get user() {
    return this.authService.user.value;
  }

  public $openSwitchModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $currentPhase: BehaviorSubject<NavPhases> = new BehaviorSubject<NavPhases>('start');
  public $journeyCurrentStepIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public $journeyNavPhaseSteps: BehaviorSubject<{ [key: string]: Step[] }>  = new BehaviorSubject<{ [key: string]: Step[] }>({
    'start': [
      {
        name: this.translateService.instant('JOURNEY.START.DESCRIBE_CHALLENGE'),
        nameTranslateString: 'JOURNEY.START.DESCRIBE_CHALLENGE',
        subLine: this.translateService.instant('JOURNEY.START.WELCOME_SUB'),
        subLineTranslateString: 'JOURNEY.START.WELCOME_SUB',
        template: 'description'
      },
      {
        name: this.translateService.instant('JOURNEY.START.TOPIC_GREETING', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.START.TOPIC_GREETING',
        subLine: this.translateService.instant('JOURNEY.START.TOPIC_SUB'),
        subLineTranslateString: 'JOURNEY.START.TOPIC_SUB',
        template: 'topic'
      },
      {
        name: this.translateService.instant('JOURNEY.START.SELECTED'),
        nameTranslateString: 'JOURNEY.START.SELECTED',
        subLine: this.translateService.instant('JOURNEY.START.WELCOME_SUB'),
        subLineTranslateString: 'JOURNEY.START.WELCOME_SUB',
        template: 'introduction'
      },
      {
        name: this.translateService.instant('JOURNEY.START.SIGNALS'),
        nameTranslateString: 'JOURNEY.START.SIGNALS',
        subLine: this.translateService.instant('JOURNEY.START.WELCOME_SUB'),
        subLineTranslateString: 'JOURNEY.START.WELCOME_SUB',
        template: 'signal-choice'
      },
      {
        name: this.translateService.instant('JOURNEY.START.WEIGHTING', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.START.WEIGHTING',
        subLine: this.translateService.instant('JOURNEY.START.WELCOME_SUB'),
        subLineTranslateString: 'JOURNEY.START.WELCOME_SUB',
        template: 'signal-weight'
      },
      {
        name: this.translateService.instant('JOURNEY.START.SUMMARY', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.START.SUMMARY',
        subLine: this.translateService.instant('JOURNEY.START.WELCOME_SUB'),
        subLineTranslateString: 'JOURNEY.START.WELCOME_SUB',
        template: 'summary'
      }
    ],
    'module_selection': [
      {
        name: this.translateService.instant('JOURNEY.START.SELECTION', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.START.SELECTION',
        subLineTranslateString: 'JOURNEY.START.SELECTION_SUB',
        subLine: this.translateService.instant('JOURNEY.START.SELECTION_SUB'),
        template: 'module_selection'
      }
    ],
    'module': [],
    'practical': [
      {
        name: this.translateService.instant('JOURNEY.PRACTICAL.CHALLENGES', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.PRACTICAL.CHALLENGES',
        subLine: this.translateService.instant('JOURNEY.PRACTICAL.CHALLENGES_SUB'),
        subLineTranslateString: 'JOURNEY.PRACTICAL.CHALLENGES_SUB',
        template: 'challenges'
      },
      {
        name: this.translateService.instant('JOURNEY.PRACTICAL.CHALLENGES', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.PRACTICAL.CHALLENGES',
        subLine: this.translateService.instant('JOURNEY.PRACTICAL.CHALLENGES_SUB'),
        subLineTranslateString: 'JOURNEY.PRACTICAL.CHALLENGES_SUB',
        template: 'break'
      }
    ],
    'follow_up': [
      {
        name: this.translateService.instant('JOURNEY.PRACTICAL.CHALLENGES', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.PRACTICAL.CHALLENGES',
        subLine: '',
        subLineTranslateString: '',
        template: 'congrats'
      },
      {
        name: this.translateService.instant('JOURNEY.FOLLOWUP.WELCOME_BACK', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.FOLLOWUP.WELCOME_BACK',
        subLine: this.translateService.instant('JOURNEY.FOLLOWUP.WELCOME_BACK_SUB'),
        subLineTranslateString: 'JOURNEY.FOLLOWUP.WELCOME_BACK_SUB',
        template: 'summary'
      },
      {
        name: this.translateService.instant('JOURNEY.FOLLOWUP.WELCOME_BACK', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.FOLLOWUP.WELCOME_BACK',
        subLine: this.translateService.instant('JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES'),
        subLineTranslateString: 'JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES',
        template: 'challenges'
      },
      {
        name: this.translateService.instant('JOURNEY.STEPS.SHORT_REFLECTION', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.STEPS.SHORT_REFLECTION',
        subLine: this.translateService.instant('JOURNEY.STEPS.ANSWER_WITH_FEW_SENTENCES'),
        subLineTranslateString: 'JOURNEY.STEPS.ANSWER_WITH_FEW_SENTENCES',
        template: 'reflection'
      },
      {
        name: this.translateService.instant('JOURNEY.STEPS.RATE_MODULE', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.STEPS.RATE_MODULE',
        subLine: this.translateService.instant('JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES'),
        subLineTranslateString: 'JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES',
        template: 'feedback'
      },
      {
        name: this.translateService.instant('JOURNEY.FOLLOWUP.NICE_PROGRESS', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.FOLLOWUP.NICE_PROGRESS',
        subLine: this.translateService.instant('JOURNEY.FOLLOWUP.HOW_PROCEED'),
        subLineTranslateString: 'JOURNEY.FOLLOWUP.HOW_PROCEED',
        template: 'proceed'
      },
      {
        name: this.translateService.instant('JOURNEY.FOLLOWUP.FINISH_HEADLINE', {name: this.user?.first_name}),
        nameTranslateString: 'JOURNEY.FOLLOWUP.FINISH_HEADLINE',
        subLine: this.translateService.instant('JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES'),
        subLineTranslateString: 'JOURNEY.FOLLOWUP.COMPLETE_CHALLENGES',
        template: 'final'
      }
    ]
  });


  public get currentPhase() {
    return this.$journeyNavPhaseSteps.value[this.$currentPhase.value];
  }

  private phaseIndexToEnum(index: string): ModuleEnrollmentPhase {
    switch (index) {
      case '0':
        return ModuleEnrollmentPhase.ModuleSelection;
      case '1':
        return ModuleEnrollmentPhase.ModuleSelection;
      case '2':
        return ModuleEnrollmentPhase.Module;
      case '3':
        return ModuleEnrollmentPhase.Practical;
      case '4':
        return ModuleEnrollmentPhase.FollowUp;
      case '5':
        return ModuleEnrollmentPhase.Completed;
      case '6':
        return ModuleEnrollmentPhase.Break;
      default:
        return ModuleEnrollmentPhase.ModuleSelection
    }
  }

  public advancePhase(pushData: boolean = false) {
    let nextPhase: NavPhases = this.getNextPhase();
    this.isPhaseNewForJourney(nextPhase).subscribe((isNew) => {
      const nextPhase = this.getNextPhase();
      this.$currentPhase.next(nextPhase);
      this.$journeyCurrentStepIndex.next(0);
      if (pushData || isNew) {
        setTimeout(() => {
          this.highestStepSoFar.next(0)
          this.journeyStepService.$Journey.next({...this.journeyStepService.$Journey.value, current_module_enrollment: {...this.journeyStepService.$Journey.value.current_module_enrollment, phase: this.phaseIndexToEnum(nextPhase)}})
        }, 900)
        this.journeyStepService.saveJourneyDataForCurrentEnrollment(nextPhase as ModuleEnrollmentPhase, 0);
      }
      if (nextPhase === 'break'){
        this.$currentPhase.next('follow_up');
        this.journeyStepService.$Journey.next({...this.journeyStepService.$Journey.value, current_module_enrollment: {...this.journeyStepService.$Journey.value.current_module_enrollment, phase: ModuleEnrollmentPhase.Break}});
      }
    })
  }

  /**
   * Check if the next phase has been reached before
   * @private
   */
  public isPhaseNewForJourney(next: NavPhases): Observable<boolean>{
    const phases = [
      'start',
      'module_selection',
      'module',
      'practical',
      'follow_up',
      'completed'
    ];
    if (next === 'module_selection') {
      return new Observable((observer) => {
        observer.next(false);
        observer.complete();
      })
    }

    if(this.journeyStepService.$Journey.value.uuid) {
      return this.journeyDataService.getCurrentPhase(this.journeyStepService.$Journey.value.uuid).pipe(map(res => {
        const currentPhase = res.data.journey?.current_module_enrollment.phase as string;
        return phases.indexOf(currentPhase) < phases.indexOf(next);
      }))
    } else {
      return of(false)
    }
  }

  public unadvancePhase() {
    this.$currentPhase.next(this.getPreviousPhase());
    this.$journeyCurrentStepIndex.next(this.$journeyNavPhaseSteps.value[this.$currentPhase.value].length - 1);
  }

  public goToPhase(phase: NavPhases) {
    this.getCurrentEnrollmentRemotePhase(this.journeyStepService.$Journey.value.uuid)
      .pipe(take(1))
      .subscribe(res => {
        const advancedPhase = res.data.journey?.current_module_enrollment.phase as string;
        // check if phase is the current most advanced phase
        if (advancedPhase === phase) {
          //get first value from subscription
          let highest = this.highestStepSoFar.value ?? 0;
          this.$currentPhase.next(phase);
          this.$journeyCurrentStepIndex.next(highest);
          return;
        }
        this.$currentPhase.next(phase);
        this.$journeyCurrentStepIndex.next(0);
      })
  }


  /**
   * These are the default steps for the module phase
   * These need to be declared outside the journeyPhaseSteps object
   * because they need to be appended to the module steps which are fetched from the backend
   * @private
   */
  private defaultModuleSteps: Step[] = [
    {
      name: this.translateService.instant('JOURNEY.STEPS.SHORT_REFLECTION'),
      nameTranslateString: 'JOURNEY.STEPS.SHORT_REFLECTION',
      subLine: this.translateService.instant('JOURNEY.STEPS.ANSWER_WITH_FEW_SENTENCES'),
      subLineTranslateString: 'JOURNEY.STEPS.ANSWER_WITH_FEW_SENTENCES',
      template: 'step-reflection',
    },
    {
      name: this.translateService.instant('JOURNEY.STEPS.TIPS_AND_TRICKS'),
      nameTranslateString: 'JOURNEY.STEPS.TIPS_AND_TRICKS',
      subLine: this.translateService.instant('JOURNEY.STEPS.READ_THE_TIPS'),
      subLineTranslateString: 'JOURNEY.STEPS.READ_THE_TIPS',
      template: 'step-tips-and-tricks',
    },
    {
      name: this.translateService.instant('JOURNEY.STEPS.RATE_MODULE'),
      nameTranslateString: 'JOURNEY.STEPS.RATE_MODULE',
      subLine: this.translateService.instant('JOURNEY.STEPS.RATE_MODULE_SUB'),
      subLineTranslateString: 'JOURNEY.STEPS.RATE_MODULE_SUB',
      template: 'step-rate-module',
    }
  ];


  /**
   * Takes in a Module and fills up the Module Steps array with the steps of the module
   * and appends the default steps
   * @param module
   * @private
   */
  private fillModuleSteps(module: Module) {
    const convertSteps = (steps: Array<ModuleStep>) => {

      const typenameToTemplate = (name: string): string => {
        switch (name) {
          case 'ModuleInputsStep':
            return 'step-inputs';
          case 'ModuleTextStep':
            return 'step-text';
          case 'ModuleToolStep':
            return 'step-tool';
          default:
            return '';
        }
      };

      const getTitleFromTool = (step: ModuleStep): string => {
        if (step.__typename === 'ModuleToolStep') {
          return step.tool?.title ?? '';
        }
        return '';
      }

      const newSteps: Step[] = [];
      steps.forEach(s => {
        let newStep: Step;
        newStep = {
          template: typenameToTemplate(s.__typename!),
          data: s,
          // @ts-ignore This seems to be necessary because there is no title on the ModuleStep interface
          name: s.title as NavPhases ?? getTitleFromTool(s),
          // @ts-ignore This seems to be necessary because there is no sub_title on the ModuleStep interface
          subLine: s.sub_title ?? undefined
      }
        newSteps.push(newStep);
      })
      return newSteps;
    }
    const steps = convertSteps(module.steps);
    const newJourneySteps = this.$journeyNavPhaseSteps.value;
    newJourneySteps[ModuleEnrollmentPhase.Module] = [...steps, ...this.defaultModuleSteps];
    this.$journeyNavPhaseSteps.next(newJourneySteps);
  }

  reset() {
    this.$currentPhase.next('start');
    this.$journeyCurrentStepIndex.next(0);
    this.first = true;
    this.journeyDataService.init();
  }

  init() {
    this.highestStepSoFar.next(0)
    this.journeyStepService.$Journey.subscribe((journey) => {
      const module = journey.current_module_enrollment?.module;
      if (module) {
        this.fillModuleSteps(module);
      }
        let currentPhase: NavPhases = 'start';
        switch (journey.current_module_enrollment?.phase) {
          case ModuleEnrollmentPhase.Module:
            currentPhase = 'module';
            break;
          case ModuleEnrollmentPhase.Practical:
            currentPhase = 'practical';
            break;
          case ModuleEnrollmentPhase.FollowUp:
            currentPhase = 'follow_up';
            break;
          case ModuleEnrollmentPhase.Break:
            currentPhase = 'follow_up';
            break;
          case ModuleEnrollmentPhase.Completed:
            currentPhase = 'module';
            break;
          default:
            break;
        }
        if (module && this.first){
          this.$currentPhase.next(currentPhase);
          this.first = false;
        }
    });
    this.journeyStepService.$Journey.pipe(first(res => {return res && !!res.current_module_enrollment} ))
      .subscribe(res => {
        this.highestStepSoFar.next(res.current_module_enrollment?.phase_step_index ?? 0)
        this.$journeyCurrentStepIndex.next(res.current_module_enrollment?.phase_step_index ?? 0)
    })
  }

  nextStep() {
    let isReadOnly = false
    this.isReadOnly(this.journeyStepService.$Journey.value.current_module_enrollment?.phase ?? '').subscribe(res => {
      res ? isReadOnly = res : false;
    const current = this.currentPhase;
    const currentIndex = this.$journeyCurrentStepIndex.value;
    if (currentIndex < current.length -1) {
      this.$journeyCurrentStepIndex.next(currentIndex + 1);
      if (window.location.pathname.split('/').pop() === 'start') {
        if (currentIndex + 1 > this.highestStepSoFar.value) {
          this.highestStepSoFar.next(currentIndex + 1)
        }
      }
      // window.location is a hacky alternative since the activatedRoute is not returning the correct url
      // we just check if it's not in the start section else we save the data
      if (window.location.pathname.split('/').pop() !== 'start' && this.journeyStepService.$Journey.value.uuid && this.$currentPhase.value !== 'start') {
        // only advance in backend if the current phase is the same as the remote phase
        this.journeyDataService.getCurrentPhase(this.journeyStepService.$Journey.value.uuid)
          .pipe(take(1))
          .subscribe((data) => {
            if(!isReadOnly) {
              if (this.$currentPhase.value === data.data.journey?.current_module_enrollment.phase) {
                if (currentIndex !== current.length -1) {
                  this.journeyStepService.saveJourneyDataForCurrentEnrollment(undefined, this.$journeyCurrentStepIndex.value);
                }
                if (currentIndex + 1 > this.highestStepSoFar.value){
                  this.highestStepSoFar.next(currentIndex + 1)
                }
              } else {
                if (currentIndex !== current.length -1) {
                  this.journeyStepService.saveJourneyDataForCurrentEnrollment(undefined);
                }
              }
            }
          });
      }
      return;
    }
    if (currentIndex === current.length -1) {
      console.log('advance phase')
      this.$journeyCurrentStepIndex.next(0);
      this.advancePhase();
    }
    })
    this.scrollService.scrollToTop();
  }

  prevStep() {
    const current = this.currentPhase;
    const currentIndex = this.$journeyCurrentStepIndex.value;
    const prevIndex = this.$journeyCurrentStepIndex.value - 1;
    if (prevIndex >= 0) {
      this.$journeyCurrentStepIndex.next(prevIndex);
    }
    if (prevIndex < 0 ){
      this.unadvancePhase();
    }
    this.scrollService.scrollToTop();
  }

  private getNextPhase() {
    const currentPhase = this.$currentPhase.value;
    switch (currentPhase) {
      case 'start':
        return 'module_selection';
      case 'module_selection':
        return 'module';
      case 'module':
        return 'practical';
      case 'practical':
        return 'break';
      case 'break':
        return 'follow_up';
      case 'follow_up':
        return 'completed';
      default:
        return 'start';
    }
  }

  private getPreviousPhase() {
    const currentPhase = this.$currentPhase.value;
    switch (currentPhase) {
      case 'start':
        return 'start';
      case 'module_selection':
        return 'start';
      case 'module':
        return 'start';
      case 'practical':
        return 'module';
      case 'break':
        return 'practical';
      case 'follow_up':
        return 'practical';
      default:
        return 'start';
    }
  }

  resetJourneyToChallenges() {
     const obs = new Observable((observer) => {
       this.journeyDataService.resetModuleEnrollmentFinishedAndBreak(parseFloat(this.journeyStepService.$Journey.value.current_module_enrollment.id!)).subscribe((res) => {
         if (res.errors) return;
         this.journeyStepService.saveJourneyDataForCurrentEnrollment(ModuleEnrollmentPhase.Practical, 0).add(() => {
           setTimeout(() => {
             observer.next();
             observer.complete();
           },500)
         });
       })
     })

    return obs;
  }

  public getCompletedPhaseIndex(phaseId:string) {
    switch (phaseId) {
      case 'start':
        return -1;
      case 'practical':
        return 3
      case 'follow_up':
        return 4;
      case 'break':
        return 4;
      case 'module':
        return 2;
      case 'end':
        return 5;
      default:
        return -1;
    }
  }

  getCurrentEnrollmentRemotePhase(uuid: string) {
    return this.journeyDataService.getCurrentPhase(uuid);
  }

  public isReadOnly(phaseId: string): Observable<boolean> {
    if(this.journeyStepService.$Journey.value.uuid) {
      return this.getCurrentEnrollmentRemotePhase(this.journeyStepService.$Journey.value.uuid).pipe(map((phase) => {
        if (this.journeyStepService.$Journey.value.completed_at) return true;
        return this.getCompletedPhaseIndex(phaseId) < this.getCompletedPhaseIndex(phase.data.journey?.current_module_enrollment.phase as string);
      } ));
    }
    return of(false)
  }

  gotoStep(stepToIndex: number) {
    if (stepToIndex < 0) return;
    if (stepToIndex >= this.currentPhase.length) return;
    this.$journeyCurrentStepIndex.next(stepToIndex);
  }

  reTranslateAllSteps() {
    // this function is used to get all translations for the steps
    const steps = this.$journeyNavPhaseSteps.value;
    Object.keys(steps).forEach((key) => {
      steps[key].forEach((step) => {
        if (step.nameTranslateString) {
          step.name = this.translateService.instant(step.nameTranslateString, {name: this.user?.first_name});
        }
        if (step.subLineTranslateString){
          step.subLine = this.translateService.instant(step.subLineTranslateString, {name: this.user?.first_name});
        }
      })
    });

    const defaultModuleSteps = this.defaultModuleSteps;
    defaultModuleSteps.forEach((step) => {
      if (step.nameTranslateString) {
        step.name = this.translateService.instant(step.nameTranslateString, {name: this.user?.first_name});
      }
      if (step.subLineTranslateString){
        step.subLine = this.translateService.instant(step.subLineTranslateString, {name: this.user?.first_name});
      }
    });

    this.$journeyNavPhaseSteps.next(steps);
  }
}
