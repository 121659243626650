import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './atoms/alert/alert.component';
import { BackdropComponent } from './atoms/backdrop/backdrop.component';
import { ButtonComponent } from './atoms/button/button.component';
import { ChipComponent } from './atoms/chip/chip.component';
import { ContentCardComponent } from './atoms/content-card/content-card.component';
import { PieChartComponent } from './atoms/pie-chart/pie-chart.component';
import { StateDotComponent } from './atoms/state-dot/state-dot.component';
import { StateIndicatorComponent } from './atoms/state-indicator/state-indicator.component';
import { UserAuthDisplayComponent } from './molecule/userAuthDisplay/userAuthDisplay.component';
import { AccordeonComponent } from './molecule/accordeon/accordeon.component';
import { RatioTabComponent } from './molecule/ratio-tab/ratio-tab.component';
import { ContextMenuComponent } from './organism/context-menu/context-menu.component';
import { ModalComponent } from './organism/modal/modal.component';
import { NavBarComponent } from './organism/nav-bar/nav-bar.component';
import { PostItButtonComponent } from './organism/post-it/post-it-button/post-it-button.component';
import { PostItModalComponent } from './organism/post-it/post-it-modal/post-it-modal.component';
import { ToolCardComponent } from './organism/tool-card/tool-card.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FileUploadComponent } from './molecule/file-upload/file-upload.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChipMapComponent } from './organism/chip-map/chip-map.component';
import { SliderComponent } from './molecule/slider/slider.component';
import { PageTitleComponent } from './organism/page-title/page-title.component';
import { JourneyNavigationComponent } from './organism/journey-navigation/journey-navigation.component';
import { JourneyNavigationItemComponent } from './organism/journey-navigation/item/journey-navigation-item.component';
import { StepReflectionComponent } from './organism/journey-navigation/step-reflection/step-reflection.component';
import { TextAreaInputComponent } from './atoms/textAreaInput/text-area-input.component';
import { StepControlsComponent } from './organism/journey-navigation/step-controls/step-controls.component';
import { ExpansionPanelWrapperComponent } from './organism/expansion-panel/wrapper/expansion-panel-wrapper.component';
import { ExpansionPanelHeaderComponent } from './organism/expansion-panel/header/expansion-panel-header.component';
import { ExpansionPanelBodyComponent } from './organism/expansion-panel/body/expansion-panel-body.component';
import { DefaultHeaderComponent } from './organism/expansion-panel/default-header/default-header.component';
import { LoaderComponent } from './organism/loader/loader.component';
import { ToolNavigationComponent } from './organism/tool-navigation/tool-navigation.component';
import {
  JourneyNavigationItemMobileComponent
} from "./organism/journey-navigation/item-mobile/journey-navigation-item-mobile.component";
import {ConditionalHeaderLayout} from "./organism/conditional-header-layout/conditional-header-layout.component";

@NgModule({
  declarations: [
    AccordeonComponent,
    AlertComponent,
    BackdropComponent,
    ButtonComponent,
    ChipComponent,
    ContentCardComponent,
    ContextMenuComponent,
    ModalComponent,
    NavBarComponent,
    PieChartComponent,
    PostItButtonComponent,
    PostItModalComponent,
    RatioTabComponent,
    ToolCardComponent,
    StateDotComponent,
    StateIndicatorComponent,
    UserAuthDisplayComponent,
    FileUploadComponent,
    FileUploadComponent,
    ChipMapComponent,
    SliderComponent,
    PageTitleComponent,
    JourneyNavigationComponent,
    JourneyNavigationItemComponent,
    JourneyNavigationItemMobileComponent,
    StepReflectionComponent,
    TextAreaInputComponent,
    StepControlsComponent,
    ExpansionPanelWrapperComponent,
    ExpansionPanelHeaderComponent,
    ExpansionPanelBodyComponent,
    DefaultHeaderComponent,
    LoaderComponent,
    ToolNavigationComponent,
    ConditionalHeaderLayout
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    TranslateModule,
    RouterLink,
    RouterLinkActive,
  ],
  exports: [
    AccordeonComponent,
    AlertComponent,
    BackdropComponent,
    ButtonComponent,
    ChipComponent,
    ContentCardComponent,
    ContextMenuComponent,
    NavBarComponent,
    PieChartComponent,
    PostItButtonComponent,
    PostItModalComponent,
    RatioTabComponent,
    ToolCardComponent,
    StateDotComponent,
    StateIndicatorComponent,
    UserAuthDisplayComponent,
    FileUploadComponent,
    ChipMapComponent,
    SliderComponent,
    PageTitleComponent,
    JourneyNavigationComponent,
    StepReflectionComponent,
    TextAreaInputComponent,
    StepControlsComponent,
    ExpansionPanelWrapperComponent,
    DefaultHeaderComponent,
    ExpansionPanelBodyComponent,
    ExpansionPanelHeaderComponent,
    LoaderComponent,
    ModalComponent,
    ToolNavigationComponent,
    ConditionalHeaderLayout
  ],
})
export class AtomicModule {}
