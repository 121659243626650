<div class="dialog">
  <div class="dialog__header">
    <div class="dialog__info">
      <svg-icon *ngIf="headerIcon"
           [svgClass]="'dialog__info-icon'"
           src="{{headerIcon}}" />
      <h3 *ngIf="headerText"
          class="dialog__info-text">
        {{headerText}}
      </h3>
    </div>
    <button tabindex="-1" class="dialog__close-button"
            (click)="cancelModal()">
      <svg-icon src="assets/img/icons/close.svg" />
    </button>
  </div>
  <div class="dialog__context"
       [class.dialog-context--footer]="!showFooter">
    <ng-content></ng-content>
  </div>
  <div *ngIf="showFooter"
       class="dialog__footer">
    <leaderio-button *ngIf="cancelButton"
                      class="button"
                      (click)="cancelModal()"
                      [icon]="'assets/img/icons/esc-with-frame.svg'"
                      [buttonStyle]="'button--secondary'">
      {{cancelButton}}
    </leaderio-button>
    <leaderio-button *ngIf="confirmButton"
                      class="button"
                      [disabled]="confirmDisabled"
                      (click)="confirmModal()"
                      [icon]="'assets/img/icons/enter-with-frame.svg'"
                      [buttonStyle]="normalConfirmButton ? 'button--primary' : 'button--danger'"
                      [buttonType]="'submit'">
      {{confirmButton}}
    </leaderio-button>
  </div>
</div>
