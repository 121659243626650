<div class="context-menu"
      [ngClass]="{
        'context-menu--left': alignX === 'left',
        'context-menu--right': alignX === 'right',
        'context-menu--right-space': alignX === 'right-space',
        'context-menu--center': alignX === 'center',
        'context-menu--top': alignY === 'top',
        'context-menu--bottom': alignY === 'bottom',
      }"
     [style]="customStyle"
     [class.context-menu--open]="open">
    <ng-content></ng-content>
</div>
<leaderio-backdrop (clicked)="toggleOpenState()" [open]="open"></leaderio-backdrop>
