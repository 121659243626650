import { Injectable } from '@angular/core';
import confetti from "canvas-confetti";

let duration = 7 * 1000;
let animationEnd = Date.now() + duration;
let skew = 1;
const colors = ['#e31c0e', '#43d916', '#1323d1', '#dbd514', '#cc12b0']

function randomValue(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

function rain(): void {
  let timeLeft = animationEnd - Date.now();
  let ticks = Math.max(200, 500 * (timeLeft / duration));
  skew = Math.max(0.8, skew - 0.001);

  confetti({
    particleCount: 1,
    spread: randomValue(500, 500),
    startVelocity: 0,
    ticks: ticks,
    colors: [colors[Math.floor(Math.random() * colors.length)]],
    origin: {
  x: Math.random(),
      // since particles fall down, skew start toward the top
      y: (Math.random() * skew) - 0.2
},
gravity: randomValue(0.4, 0.6),
    scalar: randomValue(0.4, 1),
    drift: randomValue(-0.4, 0.4)
});
if (timeLeft > 0) {
  requestAnimationFrame(rain);
}
}

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  constructor() { }

  public canon(): void {
    confetti({
      angle: this.randomValue(90, 125),
      spread: this.randomValue(500, 500),
      particleCount: this.randomValue(300, 400),
      gravity: .3,
      origin: {y: 0.4}
    })
  }

  public rain(): void {
    rain();
  }

  private randomValue(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
}
