<div class="content-card"
     [class.content-card--as-mobile-section]="asMobileSection"
     [class.content-card--with-header]="cardHeader">
  <div class="content-card__header" *ngIf="cardHeader">
    <div class="content-card__header-icon" *ngIf="cardHeaderIcon">
      <svg-icon [src]="cardHeaderIcon" />
    </div>
    <div class="content-card__header-text" *ngIf="cardHeaderText">
      <h3>{{cardHeaderText}}</h3>
    </div>
    <div class="content-card__header-actions">
      <ng-content select="[slot=actions]"></ng-content>
    </div>
  </div>
  <div class="content-card__content">
    <ng-content></ng-content>
  </div>
</div>
