import {Component, Input} from '@angular/core';

export interface RatioTab {
  title: string;
  text?: string;
}

@Component({
  selector: 'leaderio-ratio-tab',
  templateUrl: './ratio-tab.component.html',
  styleUrls: ['./ratio-tab.component.scss'],
})
export class RatioTabComponent {
  @Input() ratioTitle = '';
  @Input() ratioText = '';
  @Input() ratioSelected = false;
  @Input() readonly: boolean = false;
}
