<button class="post-it post-it--{{color}}"
        [class.post-it--drag-button]="dragButton"
     [class.post-it--selected]="selected"
     [class.post-it--chip]="type === 'chip'">
  <span [class.post-it__text--chip]="type === 'chip'"
          [class.post-it__text--selected]="selected"
          class="button post-it__text">
    {{text}}
  </span>
  <span *ngIf="type === 'postIt'" class="post-it__close" (click)="handleOutput('close')">
    <svg-icon src="assets/img/icons/close-post-it.svg" />
  </span>
</button>
