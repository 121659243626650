import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of, take, tap} from "rxjs";
import {Apollo} from "apollo-angular";
import {User, UserQueryDocument} from "../../../graphql/generated";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public user: BehaviorSubject<User | undefined>  = new BehaviorSubject<User |undefined>(undefined);

  constructor(
    private apollo: Apollo,
    private translate: TranslateService
  ) {
    this.user.subscribe(response => {
      const userLocale = response?.locale
      if (userLocale) {
        this.translate.use(userLocale.toLowerCase())
      }
    })
  }

  login(): Observable<boolean> {
    return of(true).pipe(
      tap(() => {
        const token = localStorage.getItem('token');
        if(token) {
          this.isLoggedIn.next(true);
          this.updateUser()
        }
        else {
          this.logout();
        }
      })
    );
  }

  updateUser() {
    this.apollo.query<{me:User}>({
      query: UserQueryDocument,
      fetchPolicy: 'no-cache',
    }).pipe(take(1)).subscribe((result) => {
      this.user.next(result.data.me);
    })
  }

  logout(): void {
    this.isLoggedIn.next(false);
    localStorage.removeItem('token');
    this.user.next(undefined);
  }

  getLoginStatus(): boolean {
    return !!localStorage.getItem('token')
  }

  getToken() {
    if (this.getLoginStatus()) {
      return localStorage.getItem('token')
    }
    return null;
  }
}
