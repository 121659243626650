<div #dot class="dot dot--{{dotState}}">
  <svg-icon *ngIf="dotState === 'done'" [svgClass]="'dot__img'" src="assets/img/icons/single-check-mark.svg" />
  <div *ngIf="dotState === 'active'" class="dot__inner-circle"></div>
  <div *ngIf="dotState === 'highest-pending'" class="dot__inner-circle pending-dot" ></div>
</div>
<div class="dot__popover"
        @fadeInOut
        [style.left]="left + 'px'"
        [style.top]="top + 'px'"
        *ngIf="showPopover">{{stepName | translate}}</div>
