import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'leaderio-license-component',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
})
export class LicenseComponent implements OnInit{
  public text = '';
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http.get('/assets/third-party-licenses.txt', { responseType: 'text' as 'json'}).subscribe((data: any) => {
      this.text = data;
    })
  }

}
