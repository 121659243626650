<div class="pie-chart" [style.--size]="size">
  <div class="pie-chart__chart"
       [style.--color]="color"
       [style.--percent]="percentVal"
       [style.--accentColor]="accentColor"
  >
  </div>
  <div class="pie-chart__content-wrapper">
    <div class="pie-chart__icon-wrapper">
      <svg-icon class="icon__image"
                [svgClass]="'icon__image'"
                [style.--accentColor]="accentColor"
                [src]="icon" />
    </div>
    <div class="pie-chart__text"
         [style.--accentColor]="accentColor"
    >{{text}}</div>
  </div>
</div>
