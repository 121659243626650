import {Injectable, Provider} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getToken();

    //check if base url is the same as the api url
    if (!request.url.includes(environment.graphql)) {
      return next.handle(request);
    }

    const newRequest = request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
    });

    return next.handle(newRequest);
  }
}

export const authInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
}
