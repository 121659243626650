import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'four-conditional-header-layout-component',
  templateUrl: './conditional-header-layout.component.html',
  styleUrls: ['./conditional-header-layout.component.scss'],
})
export class ConditionalHeaderLayout implements OnInit{
  public isAuth: boolean = false;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.user.subscribe(user => {
      this.isAuth = !!user;
    })
  }

}
