import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "./services/auth/auth.service";
import {DOCUMENT} from "@angular/common";
import {ScrollingService} from "./services/scrolling/scrolling.service";

@Component({
  selector: 'leaderio-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'frontend';
  supportedLanguages = ['en', 'de'];
  langLoaded = false;
  constructor(@Inject(DOCUMENT) private document: Document,
              private translate: TranslateService,
              private authService: AuthService,
              private scrollService: ScrollingService) {
    translate.addLangs(this.supportedLanguages)
    translate.setDefaultLang(this.getLocale());
    translate.use(this.getLocale());
    this.translate.get('GLOBAL.LOADED_DUMMY').subscribe((res: string) => {
      this.langLoaded = true;
    })
  }

  ngOnInit() {
    this.authService.login().subscribe();
  }

  private getLocale() {
    for(let lang of window.navigator.languages) {
      if(lang.includes('de'))
        return 'de';
      if(lang.includes('en')) {
        return 'en';
      }
    }
    return 'en';
  }

}
