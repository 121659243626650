import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

interface LoadingState {
  loading: boolean;
  message?: string;
  title?: string;
}

export interface LoadingItem {
  id: string;
  message?: string;
  title?: string;
  done: () => any;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public $loadingState: BehaviorSubject<LoadingState> = new BehaviorSubject<LoadingState>({loading: false});

  private loadingStack: LoadingItem[] = [];

  constructor() {}

  public load(message?:string, title?: string) {
    return this.registerLoadingItem(message, title);
  }

  private removeLoadingItem(id: string) {
    this.loadingStack = this.loadingStack.filter((item) => item.id !== id);
    if (this.loadingStack.length === 0) {
      this.$loadingState.next({loading: false});
    }
    else {
      this.$loadingState.next({
        loading: true,
        message: this.loadingStack[0].message,
        title: this.loadingStack[0].title
      })
    }
  }

  private registerLoadingItem(message?: string, title?: string) {
    const item: LoadingItem = {
        id: Date.now().toString(),
        message,
        title,
        done: () => this.removeLoadingItem(item.id)
    };
    this.loadingStack.push(item);
    if (this.loadingStack.length === 1) {
      this.$loadingState.next({
        loading: true,
        message: this.loadingStack[0].message,
        title: this.loadingStack[0].title
      })
    }
    return item;
  }

}
