import {Injectable} from '@angular/core';
import {
  CancelJourneyGQL,
  CreateCancelFeedbackGQL,
  CreateFeedbackInput,
  FinishJourneyInput
} from "../../../graphql/generated";
import {Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  constructor(
    private cancelJourneyMutation: CancelJourneyGQL,
    private cancelJourneyFeedbackMutation: CreateCancelFeedbackGQL,
    private router: Router
  ) {
  }

  getJourneyPercent(phase: string): number {
    switch (phase.toLowerCase()) {
      case 'module':
        return 20;
      case 'practical':
        return 40;
      case 'break':
        return 50;
      case 'follow_up':
        return 80;
      case 'completed':
        return 90;
      case 'finished':
        return 100;
      case 'canceled':
        return 0;
      default:
        return 25
    }
  }

  cancelJourney(id: string, value?: string): Observable<any> {
    const finishJourneyInput: FinishJourneyInput = {
      journey_uuid: id.toString()
    }
    if (value) {
      const feedback: CreateFeedbackInput = {
        message: 'Abgebrochene Journey mit Grund:' + value,
        type: 'NATIVE_FEEDBACK',
        url: this.router.url}
      this.cancelJourneyFeedbackMutation.mutate(
        {input: feedback}
      ).subscribe()
    }

    return this.cancelJourneyMutation.mutate(
      {finishJourneyInput}
    )

  }

  getBreakPauseDays(break_end_at: Date) {
    const dateToday = Date.now()
    const breakEnd = new Date(break_end_at)
    return Math.ceil((breakEnd.getTime() - dateToday) / (1000 * 3600 * 24));
  }

  isInBreak(break_end_at: Date) {
    const dateToday = Date.now()
    const breakEnd = new Date(break_end_at)
    const resultDate = breakEnd.getTime() - dateToday
    return resultDate > 0;
  }
}
