import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";

export type DotState =
  'waiting' |
  'active'|
  'done' |
  'highest-pending'

@Component({
  selector: 'leaderio-state-dot',
  templateUrl: './state-dot.component.html',
  styleUrls: ['./state-dot.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(200)),
    ]),
  ]
})
export class StateDotComponent {
  @Input() dotState: DotState = 'waiting';
  @Input() stepName: string = '';
  @ViewChild('dot') dot!: ElementRef<HTMLDivElement>;
  public left: number = 0;
  public top: number = 0;
  public showPopover: boolean = false;

  @HostListener('mouseenter') onMouseEnter(event: MouseEvent) {
    if (this.dotState === 'waiting') return;
    this.left = this.dot.nativeElement.getBoundingClientRect().left + this.dot.nativeElement.getBoundingClientRect().width / 2;
    this.top = this.dot.nativeElement.getBoundingClientRect().top + this.dot.nativeElement.getBoundingClientRect().height / 2;
    this.showPopover = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showPopover = false;
  }
}
