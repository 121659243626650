<textarea
  #mainTextArea
  contenteditable="true"
  class="main-Text"
  rows="1"
  [readonly]="readonly"
  [value]="value"
  (focusin)="focus.next(true)"
  (focusout)="focus.next(false)"
  (change)="changeValue($event)"
  [placeholder]="placeholder ? placeholder : (subLine | translate)"
  [attr.maxlength]="maxLength ?? null"
  (keyup)="changeValue($event)"
  [id]="id"
  [ngStyle]="(maxLength && mainTextArea.value.length >= maxLength) ? {'border-color': '#A13636'} : {}"
>
</textarea>
<div style="display: flex; justify-content: space-between">
<!--  <p class="subLine">{{ subLine | translate }}</p>-->
  <p class="subLine" *ngIf="minLength">
    <span [ngStyle]="mainTextArea.value.length < minLength || (maxLength && mainTextArea.value.length >= maxLength) ? {'color': '#A13636'} : {}">
      {{mainTextArea.value.length}}</span> / <span>{{minLength}}</span>
  </p>
</div>
