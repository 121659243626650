import {Component, OnInit} from '@angular/core';
import {ModalService} from "../../../services/modal/modal.service";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'leaderio-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss'],
})
export class TutorialModalComponent implements OnInit{

  videoUrl = '';
  constructor(private modalService: ModalService,
              private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.user.subscribe( user => {
      if(user?.welcome_video_url) {
        this.videoUrl = user.welcome_video_url;
      }
    })
    }

  public handleOutput(event: string) {
    this.modalService.close(event);
    // console.log(event)
  }
}
